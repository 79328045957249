<template>
  <div class="container">
    <!-- 页头 -->
    <content-title :nav="nav"></content-title>
    <!-- 表头 -->
    <el-button
      type="primary"
      style="float: right"
      @click="dialogFormVisiblezanzhushang = !dialogFormVisiblezanzhushang"
      size="small"
      >添加赞助商</el-button
    >
    <div class="sec-title">赞助商列表</div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column align="center" prop="id" label="id"> </el-table-column>
      <el-table-column align="center" prop="sponsors_pic " label="赞助商图片">
        <template slot-scope="scope">
          <img :src="scope.row.pic" alt="" />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="sponsors_qrcode"
        label="赞助商二维码"
      >
        <template slot-scope="scope">
          <img :src="scope.row.pic" alt="" />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="sponsors_name" label="赞助商名称">
      </el-table-column>
      <el-table-column
        align="center"
        prop="sponsors_description"
        label="赞助商简介"
        width="200"
      >
      <template slot-scope="{row}">
                <div class="overhide">
                  {{row.sponsors_description}}
                </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="sponsors_contect"
        label="赞助商联系人"
      >
      </el-table-column
      ><el-table-column
        align="center"
        prop="sponsors_phone"
        label="赞助商手机号"
      >
      </el-table-column
      ><el-table-column align="center" prop="sponsors_addr" label="赞助商地址">
      </el-table-column>
      <el-table-column align="center" prop="activity_id" label="activity_id">
      </el-table-column>
      <el-table-column align="center" prop="status" label="操作">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status != 2"
            type="success"
            @click="handleClick(scope.row, 2)"
            >上架</el-button
          >
          <el-button v-else @click="handleClick(scope.row, 1)" type="warning">下架</el-button>
        </template>
      </el-table-column>
    </el-table>
       <paging-fy  @currentPageChange="handleCurrentChange" 
      :currentPage="currentPage" :total="total"></paging-fy>

    <el-dialog :width="ks?'95%':'30%'" title="添加赞助商" :visible.sync="dialogFormVisiblezanzhushang">
      <el-form :model="zzdata">
        <el-form-item label="赞助商名字" :label-width="formLabelWidth">
          <el-input
            v-model="zzdata.sponsors_name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="赞助商简介" :label-width="formLabelWidth">
          <el-input
            v-model="zzdata.sponsors_description"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="赞助商联系人" :label-width="formLabelWidth">
          <el-input
            v-model="zzdata.sponsors_contect"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="赞助商手机号" :label-width="formLabelWidth">
          <el-input
            v-model="zzdata.sponsors_phone"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="赞助商地址" :label-width="formLabelWidth">
          <el-input
            v-model="zzdata.sponsors_addr"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item label="赞助商二维码" :label-width="formLabelWidth">
          <avatar-uploader @getImgUrl="loadSuccess0" :clear="if_clear" :url='gift_pic[0]'></avatar-uploader>
        </el-form-item>
        <el-form-item label="赞助商图片" :label-width="formLabelWidth">
          <avatar-uploader @getImgUrl="loadSuccess1" :clear="if_clear" :url='gift_pic[1]'></avatar-uploader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisiblezanzhushang = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="tianjiazanzhushang">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: {
        firstNav: "活动管理中心",
        secondNav: "赞助商管理",
      },
      tableData: [],
      zzdata: {},
      dialogFormVisiblezanzhushang: false,
      formLabelWidth: "120px",
      gift_pic:[],
      if_clear:true,
      
currentPage:1,
total:0
    };
  },
  mounted() {
    this.getList();
    this.ks=window.screen.width<768;
  },
  methods: {
    getList() {
      this.$axios({
        url:"/user/businessActivitySponsors/queryManagerListPage",
        params:{
          pageSize:5,
          currentPage:this.currentPage
        }
      }).then((res) => {
          this.tableData = res.data.data.rows;
          this.total=res.data.data.total
        });
    },
    handleCurrentChange(){
        this.getList()
    },
    handleClick(row, num) {
      let url = "/user/businessActivitySponsors/update";
      let data = {
        id: row.id,
        status: num,
      };
      this.$axios.post(url, data).then((res) => {});
    },
    loadSuccess0(img){
        this.gift_pic[0]=img
    },
    loadSuccess1(img){
        this.gift_pic[1]=img
    },
    tianjiazanzhushang() {
      let url = "/user/businessActivitySponsors/insert";
      let data = {
        status: 2,
        sponsors_pic: this.gift_pic[1],
        sponsors_qrcode: this.gift_pic[0],
        ...this.zzdata,
      };
      this.$axios.post(url, data).then((res) => {});
      this.dialogFormVisiblezanzhushang = !this.dialogFormVisiblezanzhushang;
    },
  },
};
</script>
<style scoped>
.overhide{
  height: 120px;
  overflow: auto;
}
</style>
